import React from "react";
import "./App.css";
import logo from "./assets/logo.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo Yetz!" />
        <p>Under Construction.</p>
      </header>
    </div>
  );
}

export default App;
